<template>
  <section class="hero is-fullheight-with-navbar">
    <div class="hero-body">
      <div class="container">
        <h1 style="line-height: 1.2" class="heading-font title has-text-white">
          Work on what you want,<br />
          Where you want,<br />
          When you want.
        </h1>
        <h2 class="subtitle has-text-white mt-3">Santiqi works for you</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingSection",
};
</script>

<style scoped>
.hero {
  background-image: url(../assets/images/boat-M-compressed.jpg);
  background-size: cover;
  background-position: center;
}
</style>
