<template>
  <section class="section is-small snap-point">
    <div class="container">
      <div class="level">
        <div class="column">
          <b-image
            :src="require('@/assets/images/globenet-compressed.svg')"
            alt=""
          ></b-image>
        </div>
        <div class="column is-half">
          <h1 class="title is-spaced heading-font">The experiment</h1>
          <p class="subtitle">
            Create a network of talented people and jobs. Give those people the
            power to choose what they want to work on.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingExperiment",
};
</script>
