<template>
  <section class="section">
    <div class="container">
      <div class="level">
        <div class="column">
          <h1 class="title is-spaced heading-font">You are the focus</h1>
          <p class="subtitle mb-3">
            If you've got a passion for something and you've got the skills,
            then we won't hold you back. Start or join any number of projects in
            any number of roles and sectors. <b>We believe in you!</b>
          </p>
        </div>
        <!-- Card -->
        <div class="column is-half box p-5">
          <!-- Main Content -->

          <!-- SmartTag -->
          <h1 class="mb-5 heading has-text-centered">My Projects</h1>

          <b-collapse
            class="m-2 ml-5"
            :open="true"
            aria-id="contentIdForA11y1"
            v-for="(project, key) in projects"
            :key="key"
            ><b-taglist
              attached
              slot="trigger"
              aria-controls="contentIdForA11y1"
            >
              <!-- Project/Role tags -->
              <SmartTag
                class="my-1"
                :tags="[
                  { type: 'is-project', value: project.projectName },
                  {
                    type: 'is-role',
                    value: project.roles[uid],
                  },
                ]"
              />
            </b-taglist>
            <!-- Task tags -->
            <div
              class="pl-5 my-1"
              v-for="(task, tid) in project.tasks"
              :key="tid"
            >
              <SmartTag
                size="is-small"
                :tags="[
                  { type: 'is-task', value: task.name },
                  { type: 'is-date', value: task.deadlineDate },
                  { type: 'is-skill', value: task.skills[0] },
                ]"
              /></div
          ></b-collapse>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import json from "@/assets/dummyData.json";
import SmartTag from "@/components/SmartTag.vue";
export default {
  name: "LandingExample",
  components: { SmartTag },
  data() {
    return {
      uid: "0LDBhdyqhic9kIsx2VoTv9zGXhO2",
      projects: json,
    };
  },
};
</script>
