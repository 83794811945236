<template>
  <div>
    <LandingSection />
    <LandingProjectSeekers />
    <LandingHypothesis />
    <LandingExperiment />
    <LandingExample />
    <LandingJoin />
  </div>
</template>

<style scoped></style>

<script>
import LandingExample from "@/components/LandingExample.vue";
import LandingExperiment from "@/components/LandingExperiment.vue";
import LandingHypothesis from "@/components/LandingHypothesis.vue";
import LandingJoin from "@/components/LandingJoin.vue";
import LandingProjectSeekers from "@/components/LandingProjectSeekers.vue";
import LandingSection from "@/components/LandingSection.vue";

export default {
  name: "home-view",

  components: {
    LandingExample,
    LandingExperiment,
    LandingHypothesis,
    LandingJoin,
    LandingProjectSeekers,
    LandingSection,
  },
};
</script>
