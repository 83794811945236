var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"level"},[_vm._m(0),_c('div',{staticClass:"column is-half box p-5"},[_c('h1',{staticClass:"mb-5 heading has-text-centered"},[_vm._v("My Projects")]),_vm._l((_vm.projects),function(project,key){return _c('b-collapse',{key:key,staticClass:"m-2 ml-5",attrs:{"open":true,"aria-id":"contentIdForA11y1"}},[_c('b-taglist',{attrs:{"slot":"trigger","attached":"","aria-controls":"contentIdForA11y1"},slot:"trigger"},[_c('SmartTag',{staticClass:"my-1",attrs:{"tags":[
                { type: 'is-project', value: project.projectName },
                {
                  type: 'is-role',
                  value: project.roles[_vm.uid],
                },
              ]}})],1),_vm._l((project.tasks),function(task,tid){return _c('div',{key:tid,staticClass:"pl-5 my-1"},[_c('SmartTag',{attrs:{"size":"is-small","tags":[
                { type: 'is-task', value: task.name },
                { type: 'is-date', value: task.deadlineDate },
                { type: 'is-skill', value: task.skills[0] },
              ]}})],1)})],2)})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-spaced heading-font"},[_vm._v("You are the focus")]),_c('p',{staticClass:"subtitle mb-3"},[_vm._v(" If you've got a passion for something and you've got the skills, then we won't hold you back. Start or join any number of projects in any number of roles and sectors. "),_c('b',[_vm._v("We believe in you!")])])])
}]

export { render, staticRenderFns }