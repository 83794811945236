<template>
  <section class="section snap-point">
    <div class="container">
      <div class="level">
        <div class="column">
          <b-image
            :src="require('@/assets/images/excavator-compressed.png')"
            alt="An excavator preparing to start construction."
            class="excavator"
          ></b-image>
        </div>
        <div class="column is-half">
          <div class="title is-spaced heading-font">
            Come together and build
          </div>
          <div class="subtitle">
            We're creating a platform for creatives and dreamers from all over
            the world to collaborate on projects that inspire them. We'd love
            for you to join us.
          </div>
          <button
            class="button is-primary"
            @click="$router.push({ name: 'Register' })"
          >
            <strong>Join</strong>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingJoin",
};
</script>

<style scoped>
.excavator {
  transform: scaleX(-1);
}
</style>
