<template>
  <section class="section is-medium snap-point gradient">
    <div class="container has-text-centered">
      <h1 class="title heading-font is-2 is-spaced has-text-white">
        Our Hypothesis :
      </h1>
      <p class="subtitle is-4 has-text-white">
        Passionate people working on projects of their choosing, leads to
        exceptional outcomes for everyone.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingHypothesis",
};
</script>
