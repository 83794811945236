<template>
  <section class="section is-medium snap-point">
    <div class="container has-text-centered">
      <h1 class="title is-1 my-6 heading-font">I'm Seeking...</h1>

      <div class="pb-6 level-item has-text-centered">
        <b-field>
          <b-radio-button
            v-model="radioButton"
            native-value="projects"
            type="is-primary "
            size="is-medium"
          >
            <i class="fas fa-project-diagram mr-3"></i>
            <span class="heading-font">Projects</span>
          </b-radio-button>

          <b-radio-button
            v-model="radioButton"
            native-value="collaborators"
            type="is-primary "
            size="is-medium"
          >
            <i class="fas fa-users mr-3"></i>
            <span class="heading-font">Collaborators</span>
          </b-radio-button>
        </b-field>
      </div>

      <b-carousel
        v-model="carouselPage"
        :arrow="false"
        :repeat="false"
        :indicator="false"
        :autoplay="false"
        class="py-6"
      >
        <b-carousel-item>
          <div class="columns is-justify-content-space-around">
            <RundownBox
              icon="fa-heart"
              title="Choose Work You Enjoy"
              text="If you've got the passion and the skill for something
              we won't let anyone stand in your way."
            ></RundownBox>

            <RundownBox
              icon="fa-clipboard-list"
              title="No Interviews or Tests"
              text="Hassle removed. If you've got the expertise required
              we won't doubt you."
            ></RundownBox>

            <RundownBox
              icon="fa-hand-holding-usd"
              title="Set Your Own Pay"
              text="Choose your own hours and the remuneration that you deserve for the work you carry out."
            ></RundownBox>
          </div>
        </b-carousel-item>
        <b-carousel-item>
          <div class="columns is-justify-content-space-around">
            <RundownBox
              icon="fa-user-check"
              title="Objectives Focused Hiring"
              text="The skills required to complete a project are analysed along with
              any additional constraints. A team with the appropriate expertise
              is then assembled by Santiqi. All talent is hired based on their
              objectively proven skill sets."
            ></RundownBox>

            <RundownBox
              icon="fa-atom"
              title=" Flexible Project Scaling"
              text="Change project scope on the fly. Scale up or down the rate or
              importance of tasks as new information comes in, all while keeping
              within budget."
            ></RundownBox>

            <RundownBox
              icon="fa-bolt"
              title=" Passionate Contributors"
              text="Rest easy knowing that every member of your team has chosen to
              join the project. This is their passion too!"
            ></RundownBox>
          </div>
        </b-carousel-item>
      </b-carousel>
    </div>
  </section>
</template>

<script>
import RundownBox from "@/components/RundownBox.vue";
export default {
  name: "LandingProjectSeekers",
  components: { RundownBox },
  data() {
    return {
      carouselPage: 0,
    };
  },
  computed: {
    radioButton: {
      get: function () {
        if (this.carouselPage == 1) {
          return "collaborators";
        }
        return "projects";
      },
      set: function (newValue) {
        if (newValue == "collaborators") {
          this.carouselPage = 1;
        } else {
          this.carouselPage = 0;
        }
      },
    },
  },
};
</script>
