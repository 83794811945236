<template>
  <div class="column">
    <i class="fa-stack fa-lg mb-5">
      <i class="fas fa-circle fa-stack-2x has-text-primary"></i>
      <i :class="icon" class="has-text-white fas fa-stack-1x fa-inverse"></i>
    </i>
    <h2 class="title is-4">{{ title }}</h2>
    <p class="is-size-6">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: "RundownBox",

  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    text: {
      type: String,
      required: false,
      default: "",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
